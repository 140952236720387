import React, { Component } from "react";
import Helmet from "react-helmet";
import Layout from "../layout";
import About from "../components/About";
import config from "../../data/SiteConfig";
import SEO from "../components/SEO";
import CallSticky from "components/CallSticky"
class AboutPage extends Component {
  render() {
    const seo = {
      siteTitle: "Giới thiệu về Hải Nam Media",
      siteDescription: "Với phương châm: Sử dụng các sản phẩm của mình phục vụ cho những nhu cầu thiết thực nhất của người dân để làm cho cuộc sống tốt đẹp hơn Hải Nam Media là Cầu nối thông tin ngành truyền thông.",
      siteCover: "/logos/logo-512.png"
    }
    return (
      <Layout location={this.props.location} title="About">
        <div className="about-container">
          <Helmet>
            <title>{`About | ${config.siteTitle}`}</title>
            <link rel="canonical" href={`${config.siteUrl}/about/`} />
            <link href="/logos/favicon.ico" rel="icon" type="image/x-icon" />
          </Helmet>
          <SEO nonPostSEO={seo} />
          <About />
          <CallSticky/>
        </div>
      </Layout>
    );
  }
}

export default AboutPage;
